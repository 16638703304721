//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import ProductCategories from "@/components/Product/Categories";
import { dateRangeDays } from "@/utils/dates";
import { toExportFormat } from "@/utils/export";
import _ from "lodash";
export default {
  components: {
    DataTable,
    ProductCategories
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    forceLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      oldFilters: {}
    };
  },
  methods: {
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 10000;
        query.page = 1;
      }
      if (query.field) {
        return this.$store.dispatch("category/getCategoryFilter", {
          ...query,
          ...this.reportSettings
        });
      } else {
        let filters = null;
        const keys = Object.keys(query);
        if (keys.some(key => key.startsWith("f."))) {
          filters = keys.filter(key => key.startsWith("f.")).reduce((acc, key) => {
            acc[key] = query[key];
            return acc;
          }, {});
        }
        const body = {
          ...query,
          ...this.reportSettings
        };
        if (filters) {
          body.filters = filters;
        }
        if (!_.isEqual(filters, this.oldFilters)) {
          this.$emit("updateFilters", filters);
        }
        this.oldFilters = {
          ...filters
        };
        const result = await this.$store.dispatch("category/getCategoryProducts", body);
        if (query.action === "export") {
          return toExportFormat(result, this.columns);
        } else {
          return result;
        }
      }
    }
  },
  computed: {
    columns() {
      const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
      let a = [{
        title: "Товар",
        show: true,
        name: "title",
        width: 380,
        filter: "text",
        type: "link",
        noOverflow: true,
        getImage: item => item.image,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                p_id: item.p_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.title,
            target: "_blank"
          };
        }
      }, {
        title: "Категория",
        show: true,
        name: "category",
        width: 380,
        filter: "select",
        type: "slot"
      }, {
        title: "Позиция",
        show: true,
        name: "position",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Кол-во категорий",
        show: true,
        name: "categories",
        type: "number",
        width: 100,
        filter: "numberRange"
      },
      //{{kwd ~ /src/components/Brand/ProductsTable.vue
      {
        title: "Кол-во запросов",
        show: true,
        name: "keywords",
        width: 100,
        filter: "numberRange",
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                p_id: item.p_id,
                tab: "keywordsPositions"
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.keywords,
            target: "_blank"
          };
        }
      },
      //}}kwd

      {
        title: "SKU",
        show: true,
        name: "sku",
        width: 100,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            href: item.mp_url,
            text: item.sku
          };
        }
      }, {
        title: "Бренд",
        show: true,
        name: "brand",
        width: 100,
        filter: "select",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "BrandInfo",
              params: {
                b_id: item.b_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.brand,
            target: "_blank"
          };
        }
      }];
      if (this.reportSettings.mp == "wb") {
        a = a.concat([{
          //title: "Цена",
          title: "Цена с WB кошельком",
          //wb-no-spp
          show: true,
          name: "price",
          type: "money",
          width: 100
          // filter: "numberRange",
        },
        /*
        {
            title: "СПП",
            show: true,
            name: "price_discount_spp",
            type: "percent",
            width: 100,
            filter: "numberRange",
        },
        */
        {
          //title: "Базовая цена",
          title: "Цена",
          //wb-no-spp
          show: true,
          name: "price_spp",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Скидка",
          show: true,
          name: "discount",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }]);
      } else {
        a = a.concat([{
          title: "Цена",
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Скидка",
          show: true,
          name: "discount",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }]);
      }
      a = a.concat([{
        title: "Вес, г",
        name: "p_weight",
        type: "number",
        width: 100,
        filter: "numberRange",
        show: true
      }]);
      if (this.reportSettings.mp == "ozon") {
        a = a.concat([{
          title: "Цена продавца",
          name: "p_true_seller_price",
          type: "money",
          width: 100,
          filter: "numberRange",
          show: true
        }, {
          title: "Процент выкупа, %",
          name: "p_delivered_percent",
          type: "percent",
          width: 100,
          filter: "numberRange",
          show: true
        }]);
      }
      a = a.concat([{
        title: "Отзывы",
        show: true,
        name: "p_reviews",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: `Отзывов за ${days} дней`,
        show: true,
        name: "new_reviews",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Рейтинг",
        show: true,
        name: "rating",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Склад",
        show: true,
        name: "warehouse",
        width: 120,
        filter: "select"
      }]);
      if (this.reportSettings.mp == "wb") a = a.concat([{
        title: "Кол-во картинок",
        show: true,
        name: "tot_photo",
        type: "number",
        width: 100,
        filter: "numberRange"
      }]);
      a = a.concat([{
        title: "Продавец",
        show: true,
        name: "seller",
        width: 200,
        filter: "select",
        type: "link",
        getLink: item => {
          return {
            route: {
              name: "SellerInfo",
              params: {
                s_id: item.s_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.seller,
            target: "_blank"
          };
        }
      }, {
        title: "Текущий остаток (шт)",
        show: true,
        name: "remains",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Впервые",
        show: true,
        name: "date",
        width: 100,
        filter: "dateRange",
        type: "date"
      }, {
        title: "Последний раз",
        show: true,
        name: "date2",
        width: 110,
        filter: "dateRange",
        type: "date"
      }, {
        title: `Продано за ${days} дн (шт)`,
        show: true,
        name: "sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Выручка за ${days} дн`,
        show: true,
        name: "revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Сред. продаж в день (шт)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Сред. выручка в день`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Дней в наличии`,
        show: true,
        name: "days",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `Упущенная выручка`,
        show: true,
        name: "losses",
        type: "money",
        width: 180,
        filter: "numberRange"
      }]);
      return a.map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};
//
//
//
//
//
//
//

import CategoryInfoNew from "./CategoryInfoNew.vue";
import CategoryInfoOld from "./CategoryInfoOld.vue";
export default {
  components: {
    CategoryInfoNew,
    CategoryInfoOld
  },
  data() {
    return {
      testUsers: null,
      listOnly: process.env.VUE_APP_DEMO_EXTERNAL_ANALYTIC === "false" ? false : true
    };
  },
  created() {
    this.fetchTestUsers();
  },
  methods: {
    async fetchTestUsers() {
      try {
        const response = await fetch("/test-users.json");
        const json = await response.json();
        this.testUsers = json.users;
      } catch (error) {
        console.error("Failed to fetch users:", error);
        return {};
      }
    }
  },
  computed: {
    inTheList() {
      var _this$testUsers;
      return (_this$testUsers = this.testUsers) === null || _this$testUsers === void 0 ? void 0 : _this$testUsers.includes(this.$store.state.user.user.user_id);
    },
    showNewVariant() {
      return this.listOnly ? this.inTheList : true;
    }
  }
};